.survey-popup {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 200;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .image-wrapper {
        position: relative;
        
        a {
            position: absolute;
            display: block;
            cursor: pointer;
            width: 26.4295%;
            height: 14.0470%;
            left: 36.7217%;
            top: 71.9379%;
        }
    
        span {
            position: absolute;
            display: block;
            cursor: pointer;
            width: 2.9225%;
            height: 4.8952%;
            left: 93.7738%;
            top: 5.5337%;
        }
        
        img {
            width: 100%;
            max-width: 700px;
            height: auto;
        }
    }

    .overlay-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #1c2e3b;
        transition: opacity 0.65s ease-in;
        opacity: 0.85;
    }
}